<template>
  <div class="p-grid">
    <Listbox
        v-model="selectedAlarmPointItem"
        :filter="true"
        :filterPlaceholder="_t('label_search')"
        :multiple="false"
        :options="alarmPanels"
        dataKey="name"
        listStyle="height:80vh"
        optionLabel="name"
        style="width:20rem; margin: 10px;box-shadow: 1px 1px 1px lightgrey; font-size: 12px;"
        @change="getAlarmPanel($event.value.name)"
    >
      <template #empty>
        {{ _t('label_no_alarm_panel_found') }}
      </template>
      <template #header>
        <div style="padding: 1.5rem;">
          <h3 class="p-m-0" style="line-height: .9">
            <strong>{{ _t('ALARM_PANEL') }}</strong>
            <Button v-if="!isEditable && editPermission" v-tooltip.bottom="_t('label_add_new')"
                    class="p-button-rounded p-button-success p-button-text"
                    icon="pi pi-plus" style="float: right;"
                    @click="initNewAlarmPanelDialog()"
            />
          </h3>
          <MultiSelectableBreadcrumb ref="breadcrumbData"></MultiSelectableBreadcrumb>
        </div>
      </template>
    </Listbox>
    <transition name="slide-fade">
      <Card v-show="selectedAlarmPanel.name != null" class="p-col">
        <template #title>
          <div class="p-d-flex p-jc-between">
            <div v-if="isEditable">
              <div class="p-inputgroup">
                <p class="p-inputgroup-addon">Name</p>
                <InputText
                    v-model="selectedAlarmPanel.name"
                    :placeholder="_t('label_object_name')"
                    style="height: 2rem; width: 30rem;"
                />
              </div>
              <div class="p-inputgroup">
                <p class="p-inputgroup-addon">{{ _t('label_description') }}</p>
                <InputText
                    v-model="selectedAlarmPanel.description"
                    :placeholder="_t('label_description')"
                    style="height: 2rem; width: 30rem;"
                />
              </div>
              <div class="stripedBg" style="width: 60rem;">
                <OrganizationLink v-model="selectedAlarmPanel" :editMode="true" :required-field="true"/>
              </div>
            </div>
            <div v-else>
              <h3 class="p-m-1"><strong>{{ selectedAlarmPanel.name }}</strong>: {{ selectedAlarmPanel.description }}
              </h3>
              <h6 class="p-m-1"><strong>{{ selectedAlarmPanel.orgName ? _t('label_organization') : '' }}</strong>
                {{ selectedAlarmPanel.orgName }}
              </h6>
            </div>
            <div v-if="editPermission">
              <Button v-if="isEditable" v-tooltip.bottom="_t('Cancel')"
                      class="p-button-rounded p-button-danger p-button-text"
                      icon="pi pi-times"
                      @click="editAlarmPanel(false); selectedAlarmPoint.id = null; getAlarmPanel(selectedAlarmPanel.name)"/>
              <Button v-if="isEditable" v-tooltip.bottom="_t('Save')"
                      class="p-button-rounded p-button-text p-button-success"
                      icon="pi pi-check" @click="saveAlarm()"/>
              <Button v-if="!isEditable" v-tooltip.bottom="_t('Edit')"
                      class="p-button-rounded p-button-text p-button-secondary"
                      icon="pi pi-pencil" @click="editAlarmPanel(true)"/>
              <Button v-if="!isEditable" v-tooltip.bottom="_t('Delete')"
                      class="p-button-rounded p-button-danger p-button-text"
                      icon="pi pi-trash" @click="deleteAlarmPanelDialog = true"/>
            </div>
          </div>
        </template>
        <template #content>
          <div class="py-2 p-flex-row p-d-flex" style="border-bottom: 1px solid #dee2e6;">
            <div v-for="(row, index) in selectedAlarmPanel.rows"
                 :key="row.id"
                 class="p-d-inline-flex" style="width:100%; max-width: 200px"
            >
              <div :style="activeButtonsTab == index ? 'border-bottom: 2px solid #82B541' : ''" class="p-inputgroup">
                <InputText v-if="isEditable" v-model="row.text" placeholder="Tab name"
                           @focus="activeButtonsTab = index; getAlarmPoint(null)"/>
                <span v-else class="p-text-center p-text-bold"
                      style="width: 100%; min-height: 30px; padding-top: 10px; cursor: pointer;"
                      @click="activeButtonsTab = index; getAlarmPoint(null)"
                >{{ row.text }}</span>
                <Button v-if="isEditable" v-tooltip.bottom="_t('label_delete_operation')"
                        class="p-button-rounded p-button-danger p-button-text p-mr-3"
                        icon="pi pi-times"
                        @click="getAlarmPoint(null); deleteAlarmPanelButton(selectedAlarmPanel.rows, selectedAlarmPanel.rows.indexOf(row), 'tab')"
                />
              </div>

            </div>
            <div v-if="isEditable" class="p-d-inline-flex" style="width: 50px; border-bottom: 1px solid #dee2e6">
              <div class="p-inputgroup">
                <Button v-tooltip.bottom="_t('label_add_new')"
                        class="p-button-success" icon="pi pi-plus" style="margin-right: .5em"
                        type="button"
                        @click="getAlarmPoint(null); createNewTab()"/>
              </div>

            </div>
          </div>
          <TabView v-model:activeIndex="activeButtonsTab"
                   :scrollable="true"
                   class="withCustomTabs hiddenTabs"
                   @tab-click="selectedAlarmPoint.id = null">
            <TabPanel v-for="row in selectedAlarmPanel.rows" :key="row.id">
              <div class="p-d-flex p-flex-md-row">
                <div class="p-mb-0 p-mr-2 p-ml-2" style="min-width: 25rem;">
                <span v-for="button in row.buttons" :key="button.alarmid + Math.random()">
                  <span v-if="isEditable">
                    <Card
                        :style="selectedAlarmPoint.id && selectedAlarmPoint.id === button.id ?
                                  'display:inline-block; width:25rem; height:26rem; font-size: 12px; border: 2px solid #80b53f;' :
                                  'display:inline-block; width:25rem; height:26rem; font-size: 12px;'"
                        class="p-mr-2 p-mb-2"
                    >
                      <template #header>
                        <div class="p-d-flex p-jc-between">
                          <div>
                            <Button v-tooltip.bottom="'Delete alarm button'"
                                    class="p-button-rounded p-button-danger p-button-text"
                                    icon="pi pi-times"
                                    @click="deleteAlarmPanelButton(row.buttons, row.buttons.indexOf(button), 'button')"/>
                            <h3 v-tooltip.top="button.alarmName"
                                class="p-ml-3 overflow-card-text">{{ button['custom-name'] ?? button.alarmName }}</h3>
                          </div>
                          <div class="p-p-3">
                            <i :class="`center icon_big ${button?.icon?.name || 'mdi mdi-alert-outline'}`"
                               :style="`border: 1px solid gray; color: ${button.textColor ? button.textColor :  '#ffffff'}; background-color: ${button.bgColor ? button.bgColor :  '#989898'};`"
                               @click="initIconDialog(selectedAlarmPanel.rows.indexOf(row), row.buttons.indexOf(button))"
                            />
                        </div>
                      </div>
                      </template>
                      <template #content>
                      <InputText v-model="button['custom-name']"
                                 :placeholder="_t('label_Alarm_name')"
                                 class="p-mb-2 p-mt-2"
                                 style="width:100%;"
                                 type="text"
                      />
                      <Dropdown
                          v-model="button.alarmid"
                          :filter="true"
                          :filter-placeholder="_t('label_search_by_alarm_id_or_name')"
                          :filterFields="['alarmPointId', 'name', 'id']"
                          :options="alarmPoints"
                          :placeholder="_t('menu.alarm_points')"
                          class="p-mb-2"
                          optionLabel="name"
                          optionValue="alarmPointId"
                          style="width: 100%;"
                          @change="button.id = selectAlarmPointIdForButton(button)"
                      />
                      <Dropdown
                          v-model="button.style"
                          :options="buttonStyleArray"
                          :placeholder="_t('label_select_size')"
                          class="p-mb-2"
                          optionDisabled="disabledForPanels"
                          optionLabel="name"
                          optionValue="value"
                          style="width: 100%;"
                      />
                      <br/>
                      <br/>
                      <div class="p-d-flex p-jc-between">
                        <div>
                        <p>{{ _t('label_button_colour') }}
                          <ColorPicker v-model="button.bgColor" disabled
                                       style="border: 1px solid gray"
                                       @click="initColorDialog(selectedAlarmPanel.rows.indexOf(row), row.buttons.indexOf(button), 'bgColor')"/>
                        </p>
                        </div>
                        <div>
                        <p>{{ _t('label_text_colour') }}
                          <ColorPicker v-model="button.textColor" disabled
                                       style="border: 1px solid gray"
                                       @click="initColorDialog(selectedAlarmPanel.rows.indexOf(row), row.buttons.indexOf(button), 'textColor')"/>
                        </p>
                        </div>
                      </div>
                      <br/>
                      <br/>
                      <div class="p-d-flex p-jc-between">
                        <div>
                          <Button
                              class="p-button-outlined p-button-secondary"
                              @click="getAlarmPanel(selectedAlarmPanel.name)">
                            {{ _t('Cancel') }}
                          </Button>
                        </div>
                        <div>
                          <Button
                              class="p-button-outlined p-button-help"
                              @click="getAlarmPoint(button.id)">
                            {{ _t('label_view') }}
                          </Button>
                        </div>
                      </div>
                        <div class="p-d-flex">
                          <br>
                        </div>
                      </template>
                    </Card>
                  </span>
                  <span v-else>
                    <Button
                        :disabled="!button.existable"
                        :style="selectedAlarmPoint.id && selectedAlarmPoint.id === button.id ?
                                  button.style + `background-color: ${button.bgColor}!important;` + 'border: solid 1px #80b53f;' :
                                  button.style + `background-color: ${button.bgColor}!important;`"
                        class="p-button-secondary p-mr-2 p-mb-5"
                        @click="selectedJobId = button.id; prepareActivate(button)"
                    >

                      <strong :style="`font-size:14px; color: ${button.textColor ?? '#ffffff'}!important; `">{{
                          button['custom-name'] ?? button.alarmName
                        }}</strong>
                      <br/>
                      <br/>
                          <i :class="`icon_big  ${(button.icon &&button.icon.name) || 'mdi mdi-alert-outline'}`"
                             :style="`color: ${button.textColor ? button.textColor :  '#ffffff'}`"/>
                      <br/>
                      <br/>
                    </Button>
                </span>
              </span>
                  <Button v-if="isEditable" :title="_t('label_add_new')" icon="pi pi-plus" style="margin-right: .5em"
                          type="button" @click="addNewButton(row.buttons)"/>
                </div>
              </div>
              <transition name="slide-fade">
                <div>
                  <h3 v-if="selectedAlarmPoint.id" class="p-mt-6">
                    <i :class="`icon_medium p-mr-2 ${selectedAlarmPoint.icon.name}`"
                       :style="`color: #fff; background-color: ${selectedAlarmPoint.icon.color};`"/>
                    {{ _t('label_alarmpoint') }} <strong>{{ selectedAlarmPoint.name }}</strong>
                  </h3>
                  <TabView v-show="selectedAlarmPoint.id !== null" id="showContent" class="p-mt-2"
                           style="min-height: 800px">
                    <TabPanel :header="_t('label_tab_basic_configuration')">
                      <div class="p-grid">
                        <div class="p-col-3 stripedBg" style="vertical-align: middle;">
                          <p>{{ _t('label_alarm_id') }}<sup>*</sup></p>
                        </div>
                        <div class="p-col-9 stripedBg" style="vertical-align: middle;">
                          <div v-if="isEditableAlarmPoint" class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="pi pi-key"></i>
                    </span>
                            <InputText v-model="selectedAlarmPoint.alarmPointId"
                                       :placeholder="_t('label_alarm_id')"
                                       class="p-m-0"
                                       style="width:100%;"
                                       type="text"
                            />
                          </div>
                          <p v-else><strong>{{ selectedAlarmPoint.alarmPointId }}</strong></p>
                        </div>
                        <div class="p-col-3" style="vertical-align: middle;">
                          <p>{{ _t('label_object_name') }}<sup>*</sup></p>
                        </div>
                        <div class="p-col-9" style="vertical-align: middle;">
                          <div v-if="isEditableAlarmPoint" class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="pi pi-pencil"></i>
                    </span>
                            <InputText v-model="selectedAlarmPoint.name"
                                       :placeholder="_t('label_object_name')"
                                       class="p-m-0" style="width:100%;"
                            />
                          </div>
                          <p v-else><strong>{{ selectedAlarmPoint.name }}</strong></p>
                        </div>
                        <div class="p-col-3 stripedBg" style="vertical-align: middle;">
                          <p>{{ _t('label_message_priority') }}<sup>*</sup></p>
                        </div>
                        <div class="p-col-2 stripedBg" style="vertical-align: middle;">
                          <Dropdown v-if="isEditableAlarmPoint"
                                    v-model="selectedAlarmPoint.confirmPriority"
                                    :options="[1,2,3,4,5,6,7,8,9,10]"
                                    class="p-m-0"
                                    style="width: 100%;"/>
                          <p v-else><strong>{{ selectedAlarmPoint.confirmPriority }}</strong></p>
                        </div>
                        <div class="p-col-1 stripedBg p-text-right" style="vertical-align: middle;">
                          <Checkbox v-model="selectedAlarmPoint.active"
                                    :binary="true"
                                    :disabled="!isEditableAlarmPoint"
                                    class="p-m-0"/>
                        </div>
                        <div class="p-col-6 stripedBg" style="vertical-align: middle;">
                          <p>{{ _t('label_active_alarm') }}</p>
                        </div>
                        <div class="p-col-3" style="vertical-align: middle;">
                          <p>{{ _t('label_alarm_text') }}<sup>*</sup></p>

                          <div v-if="isEditableAlarmPoint" class="p-inputgroup p-mt-5">
                    <span class="p-inputgroup-addon">
                      <i class="pi pi-envelope"></i>
                    </span>
                            <Dropdown v-model="selectedTextVar"
                                      :disabled="!isEditableAlarmPoint"
                                      :options="textVars"
                                      :placeholder="_t('select_predefined_variable')"
                                      class="p-m-0"
                                      style="width: 100%;"
                                      @change="copyTextVar"
                            />
                          </div>
                        </div>
                        <div class="p-col-9" style="vertical-align: middle;">
                          <Textarea v-if="isEditableAlarmPoint"
                                    v-model="selectedAlarmPoint.text"
                                    :placeholder="_t('label_alarm_text')"
                                    class="p-m-0"
                                    rows="5"
                                    style="width:100%"/>
                          <p v-else><strong>{{ selectedAlarmPoint.text }}</strong></p>
                        </div>
                        <div class="p-col-12">
                          <h3 class="subhead p-mt-4">&nbsp;</h3>
                        </div>
                        <div class="p-col-3 stripedBg" style="vertical-align: middle;">
                          <p>{{ _t('label_system_id') }}</p>
                        </div>
                        <div class="p-col-9 stripedBg" style="vertical-align: middle;">
                          <div v-if="isEditableAlarmPoint" class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="pi pi-pencil"></i>
                    </span>
                            <InputText v-model="selectedAlarmPoint.systemId"
                                       :placeholder="_t('label_system_id')"
                                       class="p-m-0"
                                       style="width:100%;"
                                       type="text"
                            />
                          </div>
                          <p v-else><strong>{{ selectedAlarmPoint.systemId ?? '...' }}</strong></p>
                        </div>
                        <div class="p-col-3" style="vertical-align: middle;">
                          <p>{{ _t('label_description') }}</p>
                        </div>
                        <div class="p-col-9" style="vertical-align: middle;">
                  <Textarea v-if="isEditableAlarmPoint"
                            v-model="selectedAlarmPoint.description"
                            :placeholder="_t('label_description')"
                            class="p-m-0"
                            rows="5"
                            style="width:100%"/>
                          <p v-else><strong>{{ selectedAlarmPoint.description ?? '...' }}</strong></p>
                        </div>
                        <div class="p-col-3" style="vertical-align: middle;">
                          <p>{{ _t('label_organization') }}</p>
                        </div>
                        <div class="p-col-9" style="vertical-align: middle;">
                          <OrganizationLink ref="orgLink" v-model="selectedAlarmPoint" :editMode="isEditableAlarmPoint"
                                            modelType="ALARMPOINT"/>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel :header="_t('label_participants')">
                      <div class="p-grid nested-grid">
                        <div class="p-col-3" style="vertical-align: middle;">
                          <div v-if="isEditableAlarmPoint">
                            <div v-for="(option, i) in srcOptions" :key="option" class="p-field-radiobutton">
                              <RadioButton :id="'srcOption'+i"
                                           v-model="srcOption"
                                           :disabled="!isEditableAlarmPoint"
                                           :value="option.value"
                                           name="srcOption"
                                           @change="syncSrcOptions"
                              />
                              <label :for="'srcOption'+i">{{ option.label }}</label>
                            </div>
                          </div>
                          <p v-else>
                            <SelectedOption v-model="srcOption" :options="srcOptions" optionLabel="label"
                                            optionValue="value"/>
                          </p>
                        </div>
                        <div v-if="srcOption=='EXIST'" class="p-col-9" style="vertical-align: middle;">
                          <div v-if="isEditableAlarmPoint" class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                              <i class="pi pi-exclamation-triangle"></i>
                            </span>
                            <Dropdown v-model="selectedAlarmPoint.alarmPlanId"
                                      :filter="true"
                                      :filterPlaceholder="_t('label_alarm_plan')"
                                      :options="alarmPlans"
                                      class="p-m-0"
                                      optionLabel="name"
                                      optionValue="id"
                                      style="width:100%;"
                                      @change="getAlarmPlan($event.value)"
                            />
                            <span class="p-inputgroup-addon">
                              <i v-tooltip.bottom="_t('Clear')" class="pi pi-times"
                                 @click="selectedAlarmPlan = {id: null}; selectedAlarmPoint.alarmPlanId = null"></i>
                            </span>
                          </div>
                          <p v-else>
                            <strong>
                              <SelectedOption v-model="selectedAlarmPlan.id" :options="alarmPlans" optionLabel="name"
                                              optionValue="id"/>
                            </strong>
                          </p>
                        </div>
                        <div v-if="srcOption=='NEW'" class="p-col-9" style="vertical-align: middle;">
                          <div class="p-grid">
                            <div class="p-col-4 stripedBg">
                              <p>{{ _t('label_alarm_plan') }}<sup>*</sup></p>
                            </div>
                            <div class="p-col-8 stripedBg">
                              <div class="p-inputgroup">
                                <span class="p-inputgroup-addon">
                                  <i class="pi pi-pencil"></i>
                                </span>
                                <InputText v-model="selectedAlarmPlan.name"
                                           :placeholder="_t('label_alarm_plan')"
                                           class="p-m-0"
                                           style="width:100%;"/>
                              </div>
                            </div>
                            <div class="p-col-4">
                              <p>{{ _t('label_description') }}</p>
                            </div>
                            <div class="p-col-8">
                              <div class="p-inputgroup p-mt-2">
                        <span class="p-inputgroup-addon">
                          <i class="pi pi pi-pencil"></i>
                        </span>
                                <InputText v-model="selectedAlarmPlan.description" class="p-m-0"
                                           placeholder="Alarm plan description" style="width:100%;"/>
                              </div>
                            </div>
                            <div class="p-col-4 stripedBg">
                              <p>{{ _t('label_organization') }}</p>
                            </div>
                            <div class="p-col-8 stripedBg">
                              <OrganizationLink v-model="selectedAlarmPlan" :editMode="isEditableAlarmPoint"/>
                            </div>
                          </div>
                        </div>
                        <div class="p-col-12" style="vertical-align: middle;">
                          <h3 class="subhead">{{ _t('label_pgs') }}</h3>
                          <TabView v-model:activeIndex="activeTabSimple">
                            <TabPanel :header="_t('label_standard_view')">
                              <div v-if="selectedAlarmPlan.callGroups && selectedAlarmPlan.callGroups.length > 0"
                                   class="p-grid">
                                <Card
                                    v-for="(callGroup, index) in selectedAlarmPlan.callGroups"
                                    :key="callGroup"
                                    class="p-mr-2 p-mb-2"
                                    style="display:inline-block; width:20rem; font-size: 12px;"
                                >
                                  <template #content>
                                    <div class="p-grid">
                                      <div class="p-col-9">
                                        <div v-if="isEditableAlarmPoint && srcOption != 'EXIST'" class="p-inputgroup">
                                          <span class="p-inputgroup-addon">
                                            <i class="pi pi-pencil"></i>
                                          </span>
                                          <InputText v-model="selectedAlarmPlan.callGroups[index].name"
                                                     :placeholder="_t('label_pg')" class="p-m-0" style="width:100%;"
                                          />
                                        </div>
                                        <p v-else class="color-green">
                                          <strong>{{
                                              selectedAlarmPlan.callGroups[index].name && selectedAlarmPlan.callGroups[index].name.length ?
                                                  selectedAlarmPlan.callGroups[index].name :
                                                  '...'
                                            }}
                                          </strong>
                                        </p>
                                      </div>
                                      <div class="p-col-3 p-text-right">
                                        <Button v-if="isEditableAlarmPoint && srcOption != 'EXIST'"
                                                class="p-button-danger"
                                                @click="initCallGroupDeleteDialog(index)">
                                          <i class="pi pi-trash"></i>
                                        </Button>
                                      </div>
                                      <div
                                          :class="(isEditableAlarmPoint && srcOption != 'EXIST') ? 'p-col-7' : 'p-col-9'">
                                        {{ _t('label_notification_method') }}
                                      </div>
                                      <div
                                          :class="'p-text-right ' + ((isEditableAlarmPoint && srcOption != 'EXIST') ? 'p-col-5' : 'p-col-3')">
                                        <Dropdown v-if="isEditableAlarmPoint && srcOption != 'EXIST'"
                                                  v-model="selectedAlarmPlan.callGroups[index].memberCallRule"
                                                  :options="callRules"
                                                  class="p-m-0"
                                                  optionLabel="label"
                                                  optionValue="value"
                                                  style="width:100%;"
                                                  @change="syncGroupSelectRule(selectedAlarmPlan.callGroups[index])"
                                        />
                                        <strong v-else>
                                          <SelectedOption v-model="selectedAlarmPlan.callGroups[index].memberCallRule"
                                                          :options="callRules" default="..."
                                                          optionLabel="label"
                                                          optionValue="value"/>
                                        </strong>
                                      </div>
                                      <div
                                          :class="(isEditableAlarmPoint && srcOption != 'EXIST') ? 'p-col-7' : 'p-col-9'">
                                      <span
                                          :class="selectedAlarmPlan.callGroups[index].memberCallRule == 'ALL' ? ' disabled' : ''">{{
                                          _t('label_Number_required_ack')
                                        }}</span>
                                      </div>
                                      <div
                                          :class="'p-text-right ' + ((isEditableAlarmPoint && srcOption != 'EXIST') ? 'p-col-5' : 'p-col-3')">
                                        <InputText v-if="isEditableAlarmPoint && srcOption != 'EXIST'"
                                                   v-model="selectedAlarmPlan.callGroups[index].atLeastCount"
                                                   :disabled="selectedAlarmPlan.callGroups[index].memberCallRule == 'ALL'"
                                                   class="p-m-0" min="0"
                                                   style="width: 100%;" type="number"
                                        />
                                        <strong v-else
                                                :class="selectedAlarmPlan.callGroups[index].memberCallRule == 'ALL' ? 'disabled' : ''"
                                        >
                                          {{ selectedAlarmPlan.callGroups[index].atLeastCount }}</strong>
                                      </div>
                                      <div
                                          :class="(isEditableAlarmPoint && srcOption != 'EXIST') ? 'p-col-7' : 'p-col-9'">
                                        {{ _t('label_Method_participants_selection') }}
                                      </div>
                                      <div
                                          :class="'p-text-right ' + ((isEditableAlarmPoint && srcOption != 'EXIST') ? 'p-col-5' : 'p-col-3')">
                                        <Dropdown v-if="isEditableAlarmPoint && srcOption != 'EXIST'"
                                                  v-model="selectedAlarmPlan.callGroups[index].memberSelectRule"
                                                  :options="selectRules"
                                                  class="p-m-0"
                                                  optionLabel="label"
                                                  optionValue="value"
                                                  style="width:100%;"
                                                  @change="syncGroupSelectRule(selectedAlarmPlan.callGroups[index])"
                                        />
                                        <strong v-else>
                                          <SelectedOption v-model="selectedAlarmPlan.callGroups[index].memberSelectRule"
                                                          :options="selectRules" default="..."
                                                          optionLabel="label"
                                                          optionValue="value"/>
                                        </strong>
                                      </div>
                                      <div class="p-col-8" style="vertical-align: middle;">
                                        <h3 class="subhead">{{ _t('label_participants') }}</h3>
                                      </div>
                                      <div class="p-col-4 p-text-right">
                                        <span v-if="isEditableAlarmPoint && srcOption != 'EXIST'" class="p-buttonset">
                                          <Button v-tooltip.bottom="_t('Edit')"
                                                  class="p-button-success p-button-icon-only p-button-text"
                                                  @click="initCallGroupEditDialog(selectedAlarmPlan.callGroups[index])">
                                            <i class="pi pi-plus-circle"></i>
                                          </Button>
                                          <Button
                                              v-tooltip.bottom="_t('Delete')"
                                              :disabled="!selectedCallGroupMembers[selectedAlarmPlan.callGroups[index].id] || selectedCallGroupMembers[selectedAlarmPlan.callGroups[index].id].length < 1"
                                              class="p-button-danger p-button-icon-only p-button-text"
                                              @click="removeCallGroupMembers(selectedAlarmPlan.callGroups[index])">
                                                <i class="pi pi-minus-circle"></i>
                                          </Button>
                                        </span>
                                      </div>
                                      <div class="p-col-12">
                                        <DataTable
                                            v-model:selection="selectedCallGroupMembers[selectedAlarmPlan.callGroups[index].id]"
                                            :disabled="!isEditableAlarmPoint || srcOption == 'EXIST'" :scrollable="true"
                                            :value="selectedAlarmPlan.callGroups[index].members"
                                            class="p-m-0 condensed"
                                            dataKey="id"
                                            scrollHeight="20rem"
                                            selectionMode="multiple"
                                            style="width:100%; font-size: 12px;"
                                            @row-reorder="reorderCallGroupMembers(selectedAlarmPlan.callGroups[index], $event.value)"
                                        >
                                          <Column :rowReorder="isEditableAlarmPoint && srcOption != 'EXIST'"
                                                  style="flex: 0 0 2rem"/>
                                          <Column field="title"
                                          >
                                            <template #body="slotProps">
                                              <i v-if="slotProps.data.participantId" class="pi pi-user p-mr-1"></i>
                                              <i v-else class="pi pi-users p-mr-1"></i>
                                              {{ slotProps.data.title }}
                                            </template>
                                          </Column>
                                          <Column
                                              :selectionMode="(isEditableAlarmPoint && srcOption != 'EXIST') ? 'multiple' : ''"
                                              style="flex: 0 0 2rem"/>
                                        </DataTable>
                                      </div>
                                    </div>
                                  </template>
                                </Card>
                                <div v-if="isEditableAlarmPoint && srcOption != 'EXIST'" class="p-ml-2 p-mt-2"
                                     style="display: inline-block;">
                                  <Button v-tooltip="_t('add_new')" class="p-button-success p-button-icon"
                                          icon="pi pi-plus" @click="newCallGroupDialog = true"/>
                                </div>
                              </div>
                              <div v-else>
                                <p>{{ _t('label_no_target_groups_found') }}</p>
                                <div v-if="isEditableAlarmPoint && srcOption != 'EXIST'" class="p-ml-2 p-mt-2"
                                     style="display: inline-block;">
                                  <Button v-tooltip="_t('label_add_new')" class="p-button-success p-button-icon"
                                          icon="pi pi-plus" @click="newCallGroupDialog = true"/>
                                </div>
                              </div>
                            </TabPanel>
                            <TabPanel :header="_t('label_Simplified_view')">
                              <div v-if="selectedAlarmPlan.callGroups && selectedAlarmPlan.callGroups.length > 0"
                                   class="p-grid">
                                <Card
                                    v-for="(callGroup, index) in selectedAlarmPlan.callGroups"
                                    :key="callGroup"
                                    class="p-mr-2 p-mb-2"
                                    style="display:inline-block; width:20rem; font-size: 12px;"
                                >
                                  <template #content>
                                    <div class="p-grid">
                                      <div class="p-col-9">
                                        <div v-if="isEditableAlarmPoint && srcOption != 'EXIST'" class="p-inputgroup">
                                          <span class="p-inputgroup-addon">
                                            <i class="pi pi-pencil"></i>
                                          </span>
                                          <InputText v-model="selectedAlarmPlan.callGroups[index].name"
                                                     :placeholder="_t('label_group_name')" class="p-m-0"
                                                     style="width:100%;"/>
                                        </div>
                                        <p v-else class="color-green">
                                          <strong>{{
                                              selectedAlarmPlan.callGroups[index].name && selectedAlarmPlan.callGroups[index].name.length ?
                                                  selectedAlarmPlan.callGroups[index].name :
                                                  '[No title]'
                                            }}
                                          </strong>
                                        </p>
                                      </div>
                                      <div class="p-col-3 p-text-right">
                                        <Button v-if="isEditableAlarmPoint && srcOption != 'EXIST'"
                                                class="p-button-danger"
                                                @click="initCallGroupDeleteDialog(index)">
                                          <i class="pi pi-trash"></i>
                                        </Button>
                                      </div>
                                      <div v-if="isEditableAlarmPoint && srcOption != 'EXIST'" class="p-col-12">
                                        <p><strong>{{ _t('label_select_notification_method') }}</strong>
                                        </p>
                                        <div v-for="(rule, i) in callRulesSimple"
                                             :key="rule.value"
                                             class="p-field-radiobutton">
                                          <RadioButton :id="'call_rule'+i"
                                                       v-model="selectedAlarmPlan.callGroups[index].memberCallRuleSimple"
                                                       :disabled="!isEditableAlarmPoint"
                                                       :value="rule.value"
                                                       name="callRule"
                                                       @change="syncGroupSelectRuleSimple(selectedAlarmPlan.callGroups[index])"/>
                                          <label :for="'call_rule'+i">{{ rule.label }}</label>
                                        </div>
                                      </div>
                                      <div v-else class="p-col-12">
                                        <SelectedOption
                                            v-model="selectedAlarmPlan.callGroups[index].memberCallRuleSimple"
                                            :options="callRulesSimple" defaulValue="..."
                                            optionLabel="label"
                                            optionValue="value"/>
                                      </div>
<!--                                      <div v-if="isEditableAlarmPoint && srcOption != 'EXIST'" class="p-col-8">-->
<!--                                      <span-->
<!--                                          :class="selectedAlarmPlan.callGroups[index].memberCallRule == 'ALL' ? ' disabled' : ''">-->
<!--                                        {{ _t('label_Number_required_ack') }}-->
<!--                                      </span>-->
<!--                                      </div>-->
<!--                                      <div v-if="isEditableAlarmPoint && srcOption != 'EXIST'"-->
<!--                                           class="p-col-4 p-text-right">-->
<!--                                        <InputText v-model="selectedAlarmPlan.callGroups[index].atLeastCount"-->
<!--                                                   :disabled="selectedAlarmPlan.callGroups[index].memberCallRule == 'ALL'"-->
<!--                                                   class="p-m-0" min="0"-->
<!--                                                   style="width: 100%;" type="number"/>-->
<!--                                      </div>-->
<!--                                      <div v-else class="p-col-12">-->
<!--                                        <p :class="selectedAlarmPlan.callGroups[index].memberCallRule == 'ALL' ? 'disabled' : ''">-->
<!--                                          {{ _t('label_Number_required_ack') }}-->
<!--                                          <strong style="float:right;">-->
<!--                                            {{ selectedAlarmPlan.callGroups[index].atLeastCount }}-->
<!--                                          </strong>-->
<!--                                        </p>-->
<!--                                      </div>-->
<!--                                      <div class="p-col-8" style="vertical-align: middle;">-->
<!--                                        <h3 class="subhead">{{ _t('label_participants') }}</h3>-->
<!--                                      </div>-->
<!--                                      <div class="p-col-4 p-text-right">-->
<!--                                        <span v-if="isEditableAlarmPoint && srcOption != 'EXIST'" class="p-buttonset">-->
<!--                                          <Button v-tooltip.bottom="_t('Edit')"-->
<!--                                                  class="p-button-success p-button-icon-only p-button-text"-->
<!--                                                  @click="initCallGroupEditDialog(selectedAlarmPlan.callGroups[index], true)">-->
<!--                                            <i class="pi pi-plus-circle"></i>-->
<!--                                          </Button>-->
<!--                                          <Button-->
<!--                                              v-tooltip.bottom="_t('Delete')"-->
<!--                                              :disabled="!selectedCallGroupMembers[selectedAlarmPlan.callGroups[index].id] || selectedCallGroupMembers[selectedAlarmPlan.callGroups[index].id].length < 1"-->
<!--                                              class="p-button-danger p-button-icon-only p-button-text"-->
<!--                                              @click="removeCallGroupMembers(selectedAlarmPlan.callGroups[index], true)">-->
<!--                                            <i class="pi pi-minus-circle"></i>-->
<!--                                          </Button>-->
<!--                                        </span>-->
<!--                                      </div>-->
<!--                                      <div class="p-col-12">-->
<!--                                        <DataTable-->
<!--                                            v-model:selection="selectedCallGroupMembers[selectedAlarmPlan.callGroups[index].id]"-->
<!--                                            :disabled="!isEditableAlarmPoint || srcOption == 'EXIST'" :scrollable="true"-->
<!--                                            :value="callGroup.members"-->
<!--                                            class="p-m-0 condensed"-->
<!--                                            dataKey="id"-->
<!--                                            scrollHeight="20rem"-->
<!--                                            selectionMode="multiple"-->
<!--                                            style="width:100%; font-size: 12px;"-->
<!--                                            @row-reorder="reorderCallGroupMembers(selectedAlarmPlan.callGroups[index], $event.value)"-->
<!--                                        >-->
<!--                                          <Column :rowReorder="isEditableAlarmPoint && srcOption != 'EXIST'"-->
<!--                                                  style="flex: 0 0 2rem"/>-->
<!--                                          <Column field="title"-->
<!--                                          >-->
<!--                                            <template #body="slotProps">-->
<!--                                              <i v-if="slotProps.data.participantId" class="pi pi-user p-mr-1"></i>-->
<!--                                              <i v-else class="pi pi-users p-mr-1"></i>-->
<!--                                              {{ slotProps.data.title }}-->
<!--                                            </template>-->
<!--                                          </Column>-->
<!--                                          <Column-->
<!--                                              :selectionMode="(isEditableAlarmPoint && srcOption != 'EXIST') ? 'multiple' : ''"-->
<!--                                              style="flex: 0 0 2rem"/>-->
<!--                                        </DataTable>-->
<!--                                      </div>-->
                                    </div>
                                  </template>
                                </Card>
                                <div v-if="isEditableAlarmPoint && srcOption != 'EXIST'" class="p-ml-2 p-mt-2"
                                     style="display: inline-block;">
                                  <Button v-tooltip="_t('label_Add_new_target_group')"
                                          class="p-button-success p-button-icon"
                                          icon="pi pi-plus" @click="newCallGroupDialog = true"/>
                                </div>
                              </div>
                              <div v-else>
                                <p>{{ _t('label_no_target_groups_found') }}</p>
                                <div v-if="isEditableAlarmPoint && srcOption != 'EXIST'" class="p-ml-2 p-mt-2"
                                     style="display: inline-block;">
                                  <Button v-tooltip="_t('label_add_new')" class="p-button-success p-button-icon"
                                          icon="pi pi-plus" @click="newCallGroupDialog = true"/>
                                </div>
                              </div>
                            </TabPanel>
                          </TabView>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel :header="_t('label_instruction_files')">
                      <div v-if="selectedAlarmPoint.instructionFiles && selectedAlarmPoint.instructionFiles.length > 0"
                           class="p-grid p-nogutter p-mb-4">
                        <div v-for="(instruction, index) in selectedAlarmPoint.instructionFiles"
                             :key="index"
                             class="p-col-12"
                        >
                          <Button :label="instruction.split('/').pop()"
                                  class="p-button-link p-button-text p-button-success"
                                  @click="downloadInstruction(instruction)"
                          />
                        </div>
                      </div>
                      <p v-else><b>{{ _t('label_intruction_file_missed') }}</b></p>
                    </TabPanel>
                    <TabPanel :header="_t('label_advanced')">
                      <div class="p-grid">
                        <div class="p-col-12">
                          <h3 class="subhead">{{ _t('label_more_notifications_settings') }}</h3>
                        </div>
                        <div class="p-col-3 stripedBg" style="vertical-align: middle;">
                          <p>{{ _t('label_confirm_mode') }}<sup>*</sup></p>
                        </div>
                        <div class="p-col-2 stripedBg" style="vertical-align: middle;">
                          <Dropdown v-if="isEditableAlarmPoint"
                                    v-model="selectedAlarmPoint.acknowledgeMode"
                                    :options="confirmationModes"
                                    class="p-m-0"
                                    optionLabel="label"
                                    optionValue="value"
                                    style="width: 100%;"/>
                          <p v-else><strong>
                            <SelectedOption v-model="selectedAlarmPoint.acknowledgeMode"
                                            :options="confirmationModes"
                                            optionLabel="label"
                                            optionValue="value"/>
                          </strong></p>
                        </div>
                        <div class="p-col-3 stripedBg" style="vertical-align: middle;">
                          <p :class="!isEditableAlarmPoint || selectedAlarmPoint.acknowledgeMode == 'NONE' || selectedAlarmPoint.acknowledgeMode =='MEDIA' ? 'disabled' : ''">
                            {{ _t('label_local_confirmation_time') }}<sup>*</sup></p>
                        </div>
                        <div class="p-col-2 stripedBg" style="vertical-align: middle;">
                          <InputText v-if="isEditableAlarmPoint"
                                     v-model="selectedAlarmPoint.localAcknowledgeTime"
                                     :disabled="selectedAlarmPoint.acknowledgeMode == 'NONE' || selectedAlarmPoint.acknowledgeMode =='MEDIA'"
                                     class="p-m-0"
                                     min="0"
                                     style="width:100%;"
                                     type="number"
                          />
                          <p v-else
                             :class="selectedAlarmPoint.acknowledgeMode == 'NONE' || selectedAlarmPoint.acknowledgeMode =='MEDIA' ? 'disabled' : ''">
                            <strong>{{ selectedAlarmPoint.localAcknowledgeTime }}</strong>
                          </p>
                        </div>
                        <div class="p-col-2 stripedBg" style="vertical-align: middle;">
                          <p :class="!isEditableAlarmPoint || selectedAlarmPoint.acknowledgeMode == 'NONE' || selectedAlarmPoint.acknowledgeMode =='MEDIA' ? 'disabled' : ''">
                            {{ _t('label_minutes') }}
                          </p>
                        </div>
                        <div class="p-col-3" style="vertical-align: middle;">
                          <p>{{ _t('label_media_usage_manual') }}<sup>*</sup></p>
                        </div>
                        <div class="p-col-9" style="vertical-align: middle;">
                          <Dropdown v-if="isEditableAlarmPoint"
                                    v-model="selectedAlarmPoint.mediaUsageType"
                                    :options="mediaUsageTypes"
                                    class="p-m-0"
                                    optionLabel="label"
                                    optionValue="value"
                                    style="width: 100%;"/>
                          <p v-else><strong>
                            <SelectedOption v-model="selectedAlarmPoint.mediaUsageType"
                                            :options="mediaUsageTypes"
                                            optionLabel="label"
                                            optionValue="value"/>
                          </strong></p>
                        </div>
                        <div class="p-col-3 stripedBg" style="vertical-align: middle;">
                          <p>{{ _t('label_media_usage') }}<sup>*</sup></p>
                        </div>
                        <div class="p-col-9 stripedBg" style="vertical-align: middle;">
                          <Dropdown v-if="isEditableAlarmPoint"
                                    v-model="selectedAlarmPoint.mediaProcessingType"
                                    :options="mediaProcessingTypes"
                                    class="p-m-0"
                                    optionLabel="label"
                                    optionValue="value"
                                    style="width: 100%;"/>
                          <p v-else><strong>
                            <SelectedOption v-model="selectedAlarmPoint.mediaProcessingType"
                                            :options="mediaProcessingTypes"
                                            optionLabel="label"
                                            optionValue="value"/>
                          </strong></p>
                        </div>
                        <div class="p-col-12">
                          <h3 class="subhead p-mt-4">{{ _t('label_more_alarm_settings') }}</h3>
                        </div>
                        <div class="p-col-3" style="vertical-align: middle;">
                          <p>{{ _t('label_speech_text') }}</p>
                          <Button v-if="isEditableAlarmPoint" :label="_t('label_copy_from_alarmtext')"
                                  class="p-button p-button-secondary p-mt-4"
                                  @click="selectedAlarmPoint.speechText = selectedAlarmPoint.text"/>
                        </div>
                        <div class="p-col-9" style="vertical-align: middle;">
                  <Textarea v-if="isEditableAlarmPoint"
                            v-model="selectedAlarmPoint.speechText"
                            :placeholder="_t('label_speech_text')"
                            class="p-m-0"
                            rows="5"
                            style="width:100%"/>
                          <p v-else><strong>{{ selectedAlarmPoint.speechText }}</strong></p>
                        </div>
                        <div class="p-col-3 stripedBg" style="vertical-align: middle;">
                          <p>{{ _t('label_rep_mon_interval') }}</p>
                        </div>
                        <div class="p-col-2 stripedBg" style="vertical-align: middle;">
                          <Checkbox v-model="repeatableMonitored"
                                    :binary="true"
                                    :disabled="!isEditableAlarmPoint"
                                    class="p-m-0"
                                    @click="syncRepeatableMonitored"
                          />
                        </div>
                        <div class="p-col-2 stripedBg" style="vertical-align: middle;">
                          <InputText v-if="isEditableAlarmPoint"
                                     v-model="selectedAlarmPoint.repeatMonInterval"
                                     :disabled="!repeatableMonitored"
                                     class="p-m-0"
                                     min="0"
                                     style="width:100%"
                                     type="number"/>
                          <p v-else><strong>{{ selectedAlarmPoint.repeatMonInterval }}</strong></p>
                        </div>
                        <div class="p-col-5 stripedBg" style="vertical-align: middle;">
                          <p>{{ _t('label_minutes') }}</p>
                        </div>
                        <div class="p-col-3" style="vertical-align: middle;">
                          <p>{{ _t('label_Trigger') }}<sup>*</sup></p>
                        </div>
                        <div class="p-col-9" style="vertical-align: middle;">
                          <Dropdown v-if="isEditableAlarmPoint"
                                    v-model="selectedAlarmPoint.triggering"
                                    :options="triggerings"
                                    class="p-m-0"
                                    optionLabel="label"
                                    optionValue="value"
                                    style="width: 100%;"
                          />
                          <p v-else>
                            <strong>
                              <SelectedOption v-model="selectedAlarmPoint.triggering"
                                              :options="triggerings"
                                              optionLabel="label"
                                              optionValue="value"
                              />
                            </strong>
                          </p>
                        </div>
                        <div class="p-col-3 stripedBg" style="vertical-align: middle;">
                          <p>{{ _t('label_number_of_repeats') }}<sup>*</sup></p>
                        </div>
                        <div class="p-col-2 stripedBg" style="vertical-align: middle;">
                          <InputText v-if="isEditableAlarmPoint"
                                     v-model="selectedAlarmPoint.countRepeat"
                                     class="p-m-0"
                                     min="0"
                                     style="width:100%"
                                     type="number"/>
                          <p v-else><strong>{{ selectedAlarmPoint.countRepeat }}</strong></p>
                        </div>
                        <div class="p-col-3 stripedBg" style="vertical-align: middle;">
                          <p>{{ _t('label_between_repeats') }}<sup>*</sup></p>
                        </div>
                        <div class="p-col-2 stripedBg" style="vertical-align: middle;">
                          <InputText v-if="isEditableAlarmPoint"
                                     v-model="selectedAlarmPoint.repeatBetween"
                                     class="p-m-0"
                                     min="0"
                                     style="width:100%"
                                     type="number"/>
                          <p v-else><strong>{{ selectedAlarmPoint.repeatBetween }}</strong></p>
                        </div>
                        <div class="p-col-2 stripedBg" style="vertical-align: middle;">
                          <p>{{ _t('label_minutes') }}</p>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel :header="_t('label_Icon')">
                      <div v-if="isEditableAlarmPoint" class="p-grid">
                        <div class="p-col-12">
                          <h3 class="subhead">{{ _t('label_Colour') }}</h3>
                        </div>
                        <div v-for="(color, i) in iconColors" :key="color" class="p-col-1 p-text-center">
                          <label :for="`color${i}`">
                            <div :style="`background-color: ${color};`" class="color_sample p-mb-2"/>
                          </label>
                          <RadioButton :id="`color${i}`" v-model="selectedAlarmPoint.icon.color"
                                       :disabled="!isEditableAlarmPoint"
                                       :value="color"
                                       name="color"/>
                        </div>
                        <div class="p-col-12">
                          <h3 class="subhead">{{ _t('label_Icon') }}</h3>
                        </div>
                        <Card v-for="(section, s) in icons" :key="section.label" class="p-col-3">
                          <template #title>
                            <p>{{ section.label }}</p>
                          </template>
                          <template #content>
                            <div class="p-grid">
                              <div v-for="(icon, i) in section.icons" :key="icon" class="p-col-3 p-text-center">
                                <label :for="`icon_${s}_${i}`">
                                  <i :class="`icon p-mb-2 ${icon}`"/>
                                </label>
                                <br>
                                <RadioButton :id="`icon_${s}_${i}`" v-model="selectedAlarmPoint.icon.name"
                                             :disabled="!isEditableAlarmPoint" :value="icon"
                                             name="icon"
                                />
                              </div>
                            </div>
                          </template>
                        </Card>
                      </div>
                      <div v-else>
                        <i :class="`icon_big p-mb-2 ${selectedAlarmPoint.icon.name}`"
                           :style="`color: #fff; background-color: ${selectedAlarmPoint.icon.color};`"/>
                      </div>
                    </TabPanel>
                    <TabPanel :disabled="true" :header="_t('label_calendar')">
                    </TabPanel>
                    <TabPanel :disabled="true" :header="_t('label_individual_calendar')">
                    </TabPanel>
                    <TabPanel :disabled="true" :header="_t('label_follow_alarm')">
                    </TabPanel>
                  </TabView>
                </div>
              </transition>
            </TabPanel>
          </TabView>
        </template>

      </Card>
    </transition>
  </div>
  <Dialog v-model:visible="newAlarmPanelDialog" :style="{width: '450px'}">
    <template #header>
      <h3>{{ _t('ALARM_PANEL') }} : {{ _t('label_add_new') }}</h3>
    </template>
    <div class="p-grid p-formgrid p-fluid">
      <div class="p-col-12 p-field">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i class="pi pi pi-pencil"></i>
          </span>
          <InputText v-model="selectedAlarmPanel.name" :placeholder="_t('label_object_name')"/>
        </div>
      </div>
    </div>

    <div class="p-grid p-formgrid p-fluid">
      <div class="p-col-12 p-field">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i class="pi pi pi-pencil"></i>
          </span>
          <InputText v-model="selectedAlarmPanel.description" :placeholder="_t('label_description')"/>
        </div>
      </div>
    </div>
    <div class="p-col-12 stripedBg">
      <OrganizationLink v-model="selectedAlarmPanel" :editMode="true" :required-field="true"/>
    </div>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-outlined p-button-secondary"
              @click="newAlarmPanelDialog = false; selectedAlarmPanel = initAlarmPanel"/>
      <Button :label="_t('Save')" autofocus class="p-button-success" @click="createAlarmPanel()"/>
    </template>
  </Dialog>

  <Dialog v-model:visible="deleteAlarmPanelDialog">
    <template #header>
      <h3>{{ _t('ALARM_PANEL') }} : {{ _t('Delete') }}</h3>
    </template>
    <p><strong class="color-green">{{ selectedAlarmPoint.name }}</strong>: {{ _t('label_delete_record') }}</p>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-text" icon="pi pi-times" @click="deleteAlarmPanelDialog = false"/>
      <Button :label="_t('Delete')" autofocus icon="pi pi-check" @click="deleteAlarmPanel()"/>
    </template>
  </Dialog>

  <Dialog v-model:visible="newCallGroupDialog">
    <template #header>
      <h3>{{ _t('label_target_group') }} : {{ _t('label_add_new') }}</h3>
    </template>
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <i class="pi pi pi-pencil"></i>
      </span>
      <InputText v-model="newCallGroup.name" :placeholder="_t('label_group_name')" class="p-m-0" style="width: 100%;"/>
    </div>
    <div class="p-inputgroup p-mt-2">
      <p>{{ _t('label_organization') }}: <strong>{{ selectedAlarmPlan.orgName }}</strong></p>
      <p>{{ _t('label_alarm_plan') }}: <strong>{{ selectedAlarmPlan.name }}</strong></p>
    </div>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-outlined p-button-secondary" @click="newCallGroupDialog = false"/>
      <Button :label="_t('Save')" autofocus class="p-button-success" @click="createCallGroup()"/>
    </template>
  </Dialog>
  <Dialog v-model:visible="editCallGroupDialog">
    <template #header>
      <h3>{{ _t('label_participants') }} : {{ _t('label_update') }}</h3>
    </template>
    <p>
      {{ _t('label_Update_participants_for_target_group') }}:
      <strong class="color-green">
        {{ originalCallGroup.name }}
      </strong>
    </p>
    <hr>
    <MultiSelect ref="groupP"
                 v-model="editCallGroup.participants"
                 :filter="true"
                 :options="participants" :placeholder="_t('label_participants')"
                 :style="{width: '20vw'}" optionLabel="label">
      <template #footer>
        <div class="footer-bg p-p-2 p-text-right">
          <Button autofocus class="p-mr-2" icon="pi pi-check" label="OK" @click="$refs.groupP.hide()"/>
        </div>
      </template>
    </MultiSelect>
    <hr>
    <MultiSelect ref="groupPG"
                 v-model="editCallGroup.participantGroups"
                 :filter="true"
                 :options="participantGroups" :placeholder="_t('label_participantGroups')"
                 :style="{width: '20vw'}" optionLabel="name">
      <template #footer>
        <div class="footer-bg p-p-2 p-text-right">
          <Button autofocus class="p-mr-2" icon="pi pi-check" label="OK" @click="$refs.groupPG.hide()"/>
        </div>
      </template>
    </MultiSelect>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-outlined p-button-secondary" @click="editCallGroupDialog = false"/>
      <Button :label="_t('Save')" autofocus class="p-button-success" @click="updateCallGroupMembers()"/>
    </template>
  </Dialog>
  <Dialog v-model:visible="deleteCallGroupDialog">
    <template #header>
      <h3>{{ _t('Delete') }}</h3>
    </template>
    <p><strong class="color-green">{{
        selectedAlarmPlan.callGroups[deleteCallGroupIndex].name && selectedAlarmPlan.callGroups[deleteCallGroupIndex].name.length ? selectedAlarmPlan.callGroups[deleteCallGroupIndex].name : '[No title]'
      }}</strong>: {{ _t('label_delete_record') }}</p>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-text" icon="pi pi-times" @click="deleteCallGroupDialog = false"/>
      <Button :label="_t('Delete')" autofocus icon="pi pi-check" @click="deleteCallGroup()"/>
    </template>
  </Dialog>

  <Dialog v-model:visible="selectColorDialog" style="width:50%;">
    <template #header>
      <h3>{{ _t('label_Colour') }}</h3>
    </template>
    <span v-for="(color, i) in iconColors" :key="color" class="p-col-3 p-text-center">
      <label :for="`color${i}`">
        <span :style="`background-color: ${color}; border: 1px solid gray`" class="color_sample p-mb-2"/>
        <RadioButton :id="`color${i}`"
                     v-model="selectedAlarmPanel.rows[selectedRowIndex].buttons[selectedButtonIndex][selectedType]"
                     :disabled="!isEditable"
                     :value="color"
                     class=""
                     name="color"
        />
      </label>
    </span>
  </Dialog>

  <Dialog v-model:visible="selectIconDialog" style="width:50%;">
    <template #header>
      <h3>{{ _t('label_Icon') }}</h3>
    </template>
    <Card v-for="(section, s) in icons" :key="section.label">
      <template #title>
        <p>{{ section.label }}</p>
      </template>
      <template #content>
        <div class="p-grid">
          <div v-for="(icon, i) in section.icons" :key="icon" class="p-col-3 p-text-center">
            <label :for="`icon_${s}_${i}`">
              <i :class="`icon p-mb-2 ${icon}`"/>
            </label>
            <br>
            <RadioButton :id="`icon_${s}_${i}`"
                         v-model="selectedAlarmPanel.rows[selectedRowIndex].buttons[selectedButtonIndex].icon.name"
                         :disabled="!isEditable" :value="icon"
                         name="icon"/>
          </div>
        </div>
      </template>
    </Card>
  </Dialog>
  <Dialog id="triggerAgainDlg" v-model:visible="triggerAgainDlg" :closable="false"
          :modal="true"
          position="top" style="min-width: 750px; max-width: 1000px">
    <template #header>
      <div style="width: 100%">
        <h3 :style="{
            backgroundColor: 'rgb(226, 62, 87)',
            color: '#fff',
            textAlign: 'center'
          }" class="p-m-0 p-p-3 p-d-block p-text-uppercase p-text-bold"
            style="width: 100%"
        >{{ _t('label_triggering') }}</h3>
        <h3 :style="{
            backgroundColor: (preparedTriggerBody.button.bgColor ?? 'gray'),
            color:
                  preparedTriggerBody.button.bgColor === '#ffffff' ||
                  preparedTriggerBody.button.bgColor === '#fff'?
                  '#000' : '#fff'
          }" class="p-m-0 p-p-3 p-d-block"
            style="width: 100%"
        >
          <i :class="[preparedTriggerBody?.button?.icon?.name]"></i>
          {{ preparedTriggerBody.button['custom-name'] ?? preparedTriggerBody.button.alarmName }}
        </h3>
      </div>
    </template>
    <div class="p-d-flex p-flex-column p-mt-4" style="width: 100%">
      <div class="p-d-inline-flex p-flex-row p-justify-between p-mb-4" style="width: 100%">
        <div class="p-d-inline-flex p-jc-center">
          <Dropdown
              v-model="preparedTriggerBody.priority"
              :options="[0,1,2,3,4,5,6,7,8,9,10]"
              :placeholder="_t('label_message_priority')"
              style="width:100%; height: 2rem;"
          />
        </div>
      </div>
      <div class="p-d-inline-flex p-flex-row">
        <div style="margin-bottom: 30px; width: 100%;">
          <Card style="width: 100%; background-color: rgb(228, 227, 225); margin: 0;">
            <template #title>
              <h6 style="margin-bottom: 5px;"><strong>{{ _t('label_alarm_text') }}:</strong></h6>
            </template>
            <template #content>
              <div v-if="originalAlarmDialogText && originalAlarmDialogText.includes('<original>')"
                   style="padding: 0.5rem;">
                <!--            <h6 style="margin-bottom: 5px;"><strong>{{ _t('label_add_custom_message') }}:</strong></h6>-->
                <p style="max-width:100%; margin-bottom: 0; padding: 0 0.5rem;">
                  {{ computedAlarmDialogTextArray[0] }}
                </p>
                <Textarea
                    v-model="replacedAlarmDialogText"
                    :cols="50" :placeholder="_t('label_Start_typing_here')"
                    :rows="5"
                    style="width:100%; margin-bottom: 0;"
                />
                <p style="max-width:100%; margin-bottom: 15px; padding: 0 0.5rem;">
                  {{ computedAlarmDialogTextArray[1] }}
                </p>
              </div>
              <p v-else style="max-width:100%; margin-bottom: 15px; padding: 0.5rem;">
                {{ computedAlarmDialogTextArray[0] }}
              </p>
            </template>
          </Card>
        </div>
      </div>
      <div class="p-grid">
        <div class="p-col-3 stripedBg">
          <p>{{ _t('label_instruction_files') }}</p>
        </div>
        <div class="p-col-5 stripedBg">
          <Dropdown v-model="preparedTriggerBody.instructionFile"
                    :options="instructions"
                    class="p-m-0"
                    optionLabel="name"
                    optionValue="path"
                    style="width: 100%;"
          >
            <template #option="slotProps">
              <div class="p-dropdown-option">
                        <span v-if="slotProps.option.org.id != 1">
                          {{ slotProps.option.org.fullOrgPath.replace('[Total system]-', '') }} -
                        </span> {{ slotProps.option.name }}
              </div>
            </template>
          </Dropdown>
        </div>
        <div class="p-col-4 stripedBg">
          <Button :disabled="!preparedTriggerBody.instructionFile"
                  :label="_t('label_assign')"
                  class="p-button-success"
                  @click="addInstruction"
          />
          <span style="float: right"><UploadFile @uploaded="loadInstructions"/></span>
        </div>
      </div>
      <div v-if="preparedTriggerBody.instructionFiles.length > 0" class="p-flex-row"
           style="width: 100%"
      >
        <div
            v-for="(instruction, index) in preparedTriggerBody.instructionFiles"
            :key="index"
        >
          <Button :label="instruction.split('/').pop()"
                  class="p-button-link p-button-text p-button-success"
                  @click="downloadInstruction(instruction)"
          />
          <Button class="p-button-rounded p-button-danger p-button-text"
                  icon="pi pi-times"
                  @click="deleteIntruction(instruction)"
          />
          <br/>
        </div>
      </div>
      <p v-else>
        <b>
          {{ _t('label_intruction_file_missed') }}
        </b>
      </p>
    </div>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-danger" icon="pi pi-times" @click="cancelTriggerAlarm"/>
      <Button :label="_t('label_Trigger')" autofocus class="p-button-success" icon="pi pi-check" @click="activate"/>
    </template>
  </Dialog>
</template>
<script>
import {getList} from '@/api/list'
import {createAlarmPanel, deleteAlarmPanel, getAlarmPanel, getAlarmPanels, updateAlarmPanel} from '@/api/alarmPanel'
import {showOrg} from '@/api/organization'
import {getAlarmPlan} from '@/api/alarmPlan'
import {downloadInstruction} from '@/api/instruction'
import OrganizationLink from '../../components/ixarma/OrganizationLink'
import {getAlarmPoint, updateAlarmPoint} from "../../api/alarmPoint";
import MultiSelectableBreadcrumb from "@/components/ixarma/MultiSelectableBreadcrumb";
import {activateAlarmPanel} from "../../api/alarmPanel";
import {getInstructionFiles} from '@/api/attachments';
import UploadFile from '@/components/UploadFile'

export default {
  name: 'index',
  components: {
    'MultiSelectableBreadcrumb': MultiSelectableBreadcrumb,
    OrganizationLink, UploadFile
  },
  data() {
    return {
      activeTab: 0,
      alarmPoints: null,
      alarmPanels: null,
      selectedAlarmPointItem: null,
      initAlarmPoint: {
        id: null,
        icon: {
          name: null,
          color: null
        }
      },
      instructionFiles: [],
      selectedAlarmPoint: this.initAlarmPoint,
      isEditable: false,
      isEditableAlarmPoint: false,
      alarmPanelsearch: '',
      deleteAlarmPanelDialog: false,
      newAlarmPanelDialog: false,
      selectColorDialog: false,
      selectedRowIndex: null,
      selectedButtonIndex: null,
      selectedType: 'bgColor',
      colorDialogModel: null,
      selectIconDialog: false,
      iconDialogModel: {
        name: 'mdi mdi-alert-outline',
        color: '#989898'
      },
      selectedTextVar: null,
      textVars: [
        '<original>', '<K>', '<G>', '<family>', '<callerid>'
      ],
      selectedVar: null,
      confirmationModes: [],
      mediaUsageTypes: [],
      mediaProcessingTypes: [],
      triggerings: [],
      srcOptions: [],
      instructions: [],
      srcOption: null,
      repeatableMonitored: false,
      activeTabSimple: 0,
      selectedAlarmPlan: {
        id: null
      },
      alarmPlans: [],
      initAlarmPanel: {
        name: null,
        description: null,
        orgId: localStorage.getItem('orgId'),
        rows: []
      },
      selectedAlarmPanel: {
        name: null,
        description: null,
        rows: []
      },
      selectedCallGroupMembers: {},
      callRules: [],
      callRulesSimple: [],
      selectRules: [],
      triggerAgainDlg: false,
      preparedTriggerBody: {
        "alarmText": "",
        "button": {},
        "instructionFiles": [],
        "tempAlarmPoint": null,
        "priority": 1
      },
      selectedJobId: null,
      participants: [],
      participantGroups: [],
      newCallGroupDialog: false,
      newCallGroup: {},
      editCallGroupDialog: false,
      originalCallGroup: null,
      editCallGroup: {
        participants: [],
        participantGroups: [],
      },
      deleteCallGroupDialog: false,
      deleteCallGroupIndex: -1,
      participantFilterValue: '',
      treeParams: '',
      orgId: null,
      checkOrgIdIntervalId: null,
      buttonStyleArray: [],
      iconColors: [
        '#3996f3',
        '#314c5d',
        '#fad165',
        '#85b44a',
        '#8c5cfd',
        '#46c1d3',
        '#e23e57',
        '#989898',
        '#1b499e',
        '#f5856d',
        '#d9d9d9',
        '#ffff66',
        '#ffffff'
      ],
      featuredIcons: [
        'mdi mdi-alert-outline',
        'mdi mdi-alert-box-outline',
        'mdi mdi-exit-to-app',
        'mdi mdi-fire',
        'mdi mdi-water-alert-outline',
        'mdi mdi-hospital-box-outline',
        'mdi mdi-wrench-outline',
        'mdi mdi-lan-disconnect',
        'mdi mdi-alarm-light-outline',
        'mdi mdi-shield-alert-outline',
        'mdi mdi-account-alert-outline',
        'mdi mdi-lock-open-outline',
        'mdi mdi-help-circle-outline',
        'mdi mdi-information-outline'
      ],
      icons: [],
      activeButtonsTab: 0,
      editPermission: false,
      originalAlarmDialogText: '',
      computedAlarmDialogTextArray: [],
      replacedAlarmDialogText: '',
      initAlarmPanelName: ''
    }
  },
  mounted() {
    this.mediaProcessingTypes = [
      {value: 'CONFERENCE_CALL', label: this._t('CONFERENCE_CALL')},
      {value: 'BY_RANK', label: this._t('BY_RANK')}
    ]
    this.buttonStyleArray = [
      {
        name: this._t('label_Small'),
        value: 'display:inline-block; width:10rem; height:10rem;'
      },
      {
        name: this._t('label_Medium'),
        value: 'display:inline-block; width:12rem; height:12rem;'
      },
      {
        name: this._t('label_Large'),
        value: 'display:inline-block; width:16rem; height:16rem;'
      }
    ]
    this.triggerings = [
      {value: 'WHEN_ON', label: this._t('WHEN_ON')},
      {value: 'WHEN_ON_AND_OFF', label: this._t('WHEN_ON_AND_OFF')}
    ]
    this.mediaUsageTypes = [
      {value: 'ALL', label: this._t('label_as_configured')},
      {value: 'VOICE', label: this._t('label_voice_only')},
      {value: 'TEXT', label: this._t('label_text_only')}
    ]
    this.confirmationModes = [
      {value: 'NONE', label: this._t('_NONE')},
      {value: 'MEDIA', label: this._t('_MEDIA')},
      {value: 'PARTICIPANT', label: this._t('_PARTICIPANT')},
      {value: 'MEDIA_AND_PARTICIPANT', label: this._t('_MEDIA_AND_PARTICIPANT')}
    ]
    this.icons = [
      {
        label: this._t('menu.general'),
        icons: [
          'mdi mdi-alert-outline',
          'mdi mdi-bell-ring-outline',
          'mdi mdi-alarm',
          'mdi mdi-alert-rhombus-outline',
          'mdi mdi-bell-alert-outline',
          'mdi mdi-car-brake-alert',
          'mdi mdi-alert-circle-outline',
          'mdi mdi-alert-octagon-outline',
          'mdi mdi-alert-decagram-outline',
          'mdi mdi-alert-box-outline',
          'mdi mdi-weather-cloudy-alert',
          'mdi mdi-alarm-multiple',
          'mdi mdi-bell-circle-outline',
        ]
      },
      {
        label: this._t('label_Alarms'),
        icons: [
          'mdi mdi-exit-run',
          'mdi mdi-exit-to-app',
          'mdi mdi-fire',
          'mdi mdi-fire-extinguisher',
          'mdi mdi-air-horn',
          'mdi mdi-fire-hydrant',
          'mdi mdi-water-alert-outline',
          'mdi mdi-water-remove-outline',
          'mdi mdi-pipe-leak',
          'mdi mdi-ambulance',
          'mdi mdi-medical-bag',
          'mdi mdi-hospital-box-outline',
          'mdi mdi-zodiac-aquarius',
          'mdi mdi-wrench-outline',
          'mdi mdi-server-off',
          'mdi mdi-lan-disconnect',
          'mdi mdi-alarm-light-outline',
        ]
      },
      {
        label: this._t('label_Danger'),
        icons: [
          'mdi mdi-alarm-light-outline',
          'mdi mdi-hazard-lights',
          'mdi mdi-triforce',
          'mdi mdi-molecule-co2',
          'mdi mdi-flash-alert-outline',
          'mdi mdi-bottle-tonic-skull-outline',
          'mdi mdi-skull-crossbones-outline',
          'mdi mdi-skull',
          'mdi mdi-virus-outline',
          'mdi mdi-bacteria-outline',


        ]
      },
      {
        label: this._t('label_Security'),
        icons: [
          'mdi mdi-shield-alert-outline',
          'mdi mdi-shield-key-outline',
          'mdi mdi-account-alert-outline',
          'mdi mdi-security-network',
          'mdi mdi-security',
          'mdi mdi-lock-open-alert',
          'mdi mdi-lock-open-outline',
          'mdi mdi-key-outline',
          'mdi mdi-key-remove',
          'mdi mdi-home-alert',
          'mdi mdi-home-lock-open',
          'mdi mdi-window-shutter-alert',
          'mdi mdi-volume-off',
        ]
      },
      {
        label: this._t('label_Maintenance'),
        icons: [
          'mdi mdi-broom',
          'mdi mdi-rake',
          'mdi mdi-hammer-wrench',
          'mdi mdi-wrench-outline',
        ]
      },
      {
        label: this._t('label_IT'),
        icons: [
          'mdi mdi-help-circle-outline',
          'mdi mdi-lan-disconnect',
          'mdi mdi-server-off',
          'mdi mdi-desktop-classic',
          'mdi mdi-content-save-alert-outline',
          'mdi mdi-disc-alert',
          'mdi mdi-timeline-alert-outline',
          'mdi mdi-folder-key-outline',
          'mdi mdi-folder-alert',
          'mdi mdi-table-alert',
          'mdi mdi-wifi-strength-alert-outline',
          'mdi mdi-restart-alert',

        ]
      },
      {
        label: this._t('label_Info_Calendar'),
        icons: [
          'mdi mdi-information-outline',
          'mdi mdi-information-variant',
          'mdi mdi-clock-alert-outline',
          'mdi mdi-calendar-alert',
          'mdi mdi-exclamation',
          'mdi mdi-exclamation-thick',
          'mdi mdi-clipboard-alert-outline',
          'mdi mdi-sticker-alert-outline',
          'mdi mdi-coffee-outline',
        ]
      },
      {
        label: this._t('label_Traffic'),
        icons: [
          'mdi mdi-bus-alert',
          'mdi mdi-subway-alert-variant',
          'mdi mdi-traffic-light',
        ]
      },
      {
        label: this._t('label_Technical'),
        icons: [
          'mdi mdi-coolant-temperature',
          'mdi mdi-radioactive',
          'mdi mdi-printer-3d-nozzle-alert-outline',
          'mdi mdi-tray-alert',
          'mdi mdi-beaker-alert-outline',
          'mdi mdi-water-percent-alert',
          'mdi mdi-thermometer-alert',
          'mdi mdi-thermometer-lines',
          'mdi mdi-oil-level',
          'mdi mdi-dishwasher-alert',
          'mdi mdi-battery-alert-variant-outline',
          'mdi mdi-vibrate',
          'mdi mdi-watch-vibrate',
          'mdi mdi-fuse-alert',
          'mdi mdi-engine-outline',
          'mdi mdi-fridge-alert-outline',
          'mdi mdi-state-machine',
          'mdi mdi-gas-cylinder',
          'mdi mdi-diving-scuba-tank',
          'mdi mdi-fan-alert',
          'mdi mdi-lightbulb-on-outline',
          'mdi mdi-power-plug-off-outline',
          'mdi mdi-car-tire-alert',
          'mdi mdi-lightning-bolt-outline',
          'mdi mdi-transmission-tower',
          'mdi mdi-scale-balance',
        ]
      },
      {
        label: this._t('label_Weather'),
        icons: [
          'mdi mdi-snowflake-alert',
          'mdi mdi-snowflake-melt',
          'mdi mdi-weather-cloudy-alert',
          'mdi mdi-weather-lightning',
          'mdi mdi-weather-pouring',
        ]
      },
      {
        label: this._t('label_media'),
        icons: [
          'mdi mdi-book-open-variant',
          'mdi mdi-cellphone-basic',
          'mdi mdi-cellphone',
          'mdi mdi-monitor',
          'mdi mdi-alarm-light-outline',
          'mdi mdi-phone-in-talk',
          'mdi mdi-email',
          'mdi mdi-card-text-outline',
          'mdi mdi-cellphone-text',
          'mdi mdi-message-text',
          'mdi mdi-cellphone-wireless',
          'mdi mdi-message-video',
          'mdi mdi-application-import',
          'mdi mdi-database-import',
          'mdi mdi-phone-voip',
          'mdi mdi-application-export',
          'mdi mdi-printer',
          'mdi mdi-database-export',
          'mdi mdi-motion-sensor',
          'mdi mdi-switch',
          'mdi mdi-serial-port',
          'mdi mdi-lighthouse',
          'mdi mdi-lighthouse',
          'mdi mdi-led-on',
          'mdi mdi-cup-water',
          'mdi mdi-gesture-tap-hold',
        ]
      }
    ]
    this.callRulesSimple = [
      {value: 'A', label: this._t('label_Plan_Option1_text')},
      {
        value: 'B',
        label: this._t('label_Plan_Option2_text')
      },
      {
        value: 'C',
        label: this._t('label_Plan_Option3_text')
      },
      {value: 'OTHER', label: 'Simple view configuration is not applicable'}
    ]
    this.selectRules = [
      {value: 'NONE', label: this._t('_NONE')},
      {value: 'RANDOM', label: this._t('label_random')},
      {value: 'ESCALATION', label: this._t('label_escalation')}
    ]
    this.callRules = [
      {value: 'ALL', label: this._t('ALL')},
      {value: 'PRECISELY', label: this._t('PRECISELY')},
      {value: 'MINIMUM', label: this._t('MINIMUM')}
    ]
    this.srcOptions = [
      {value: 'EXIST', label: this._t('label_Selected_plan')},
      {value: 'NEW', label: this._t('label_Define_new_plan')},
      {value: 'SIMPLE', label: this._t('label_Simplified_entry')},
    ]
    const authorities = JSON.parse(localStorage.getItem('user')).authorities
    this.editPermission = authorities.indexOf("ALARMPANEL_EDIT") !== -1
    this.getAlarmPoints()
    this.checkOrgId()
    this.getAlarmPlans()
    this.loadInstructions()
    if (this.$route.params.id) {
      this.getAlarmPoint(this.$route.params.id)
    }
  },
  watch: {
    selectIconDialog(value) {
      if (value === false) {
        this.selectedRowIndex = null
        this.selectedButtonIndex = null
      }
    },
    replacedAlarmDialogText(value) {
      this.preparedTriggerBody.alarmText = this.originalAlarmDialogText.replace('<original>', value)
    }
  },
  beforeUnmount() {
    clearInterval(parseInt(this.checkOrgIdIntervalId))
  },
  methods: {
    addNewButton(buttons) {
      buttons.push({alarmName: '', alarmid: '', textColor: '#314C61', icon: {name: 'mdi mdi-alert-outline'}, bgColor:'#ffffff'})
      console.log(buttons)
    },
    editAlarmPanel(value)
    {
      this.isEditable = value
      if (value === true) {
        this.initAlarmPanelName = this.selectedAlarmPanel.name
      }
    },
    checkOrgId() {
      this.checkOrgIdIntervalId = setInterval(() => {
        if (this.orgId !== localStorage.getItem('orgId')) {
          this.orgId = localStorage.getItem('orgId')
          this.getAlarmPanels()
        }
      }, 100);
    },

    getAlarmPanels() {
      this.selectedAlarmPoint = this.initAlarmPoint
      getAlarmPanels(this.orgId).then((response) => {
        this.alarmPanels = response.data.sort((a, b) => a.name.localeCompare(b.name))
        this.loading = false
      })
    },
    getAlarmPanel(name, tab = 0) {
      this.activeButtonsTab = tab
      this.selectedAlarmPoint = this.initAlarmPoint
      if (name !== null) {
        getAlarmPanel(name).then((response) => {
          this.selectedAlarmPanel = response.data
          this.loading = false
          if (this.selectedAlarmPanel.rows !== null && this.selectedAlarmPanel.rows.length > 0) {
            for (let row of this.selectedAlarmPanel.rows) {
              if (row.buttons === null) {
                row.buttons = [
                  {
                    bgColor: '#ffffff',
                    textColor: '#314C61',
                    icon: {
                      name: 'mdi mdi-alert-outline'
                    }
                  }
                ]
              }
              for (let button of row.buttons) {
                if (button.style === null) {
                  button.style = `${this.buttonStyleArray[0].value}`
                }
                if (typeof button.icon === 'string') {
                  button.icon = JSON.parse(button.icon)
                }
                if (button.bgColor === null) {
                  button.bgColor = '#ffffff'
                }
                if (button.textColor === null) {
                  button.textColor = '#314C61'
                }
              }

            }
          }
          if (this.selectedAlarmPanel.orgId !== null) {
            showOrg(this.selectedAlarmPanel.orgId).then((response) => {
              this.selectedAlarmPanel.orgName = response.data.name
            });
          }
        })
      } else {
        this.selectedAlarmPanel = this.initAlarmPanel
      }
    },
    selectAlarmPointIdForButton(button) {
      let id = this.alarmPoints.filter(element => {
        return element.alarmPointId === button.alarmid
      })[0].id
      this.getAlarmPoint(id)
      return id
    },
    getAlarmPoint(id) {
      if (id === null) {
        this.selectedAlarmPoint = this.initAlarmPoint
        return
      }
      getAlarmPoint(id).then((response) => {
        this.isEditable = true
        this.selectedAlarmPoint = response.data
        if (this.selectedAlarmPoint.repeatMonInterval && this.selectedAlarmPoint.repeatMonInterval != 0) {
          this.repeatableMonitored = true
        }
        if (this.selectedAlarmPoint.alarmPlanId) {
          this.getAlarmPlan(this.selectedAlarmPoint.alarmPlanId)
        }
        try {
          this.selectedAlarmPoint.icon = JSON.parse(this.selectedAlarmPoint.icon)
        } finally {
          if (!this.selectedAlarmPoint.icon || typeof this.selectedAlarmPoint.icon !== 'object') {
            this.selectedAlarmPoint.icon = {
              color: '#989898',
              name: 'mdi mdi-alert-outline'
            }
          }
        }
        this.srcOption = 'EXIST'
        this.loading = false
      })
          .then(() => {
            const anchor = document.querySelector('body')
            anchor.scrollIntoView({
              block: "end",
              inline: "nearest",
              behavior: "smooth"
            });
          })

    },
    deleteAlarmPanelButton(buttons, index, type = 'button') {
      this.$confirm.require({
        message: type === 'tab' ? "Are you sure you would like to delete this tab?\nAll alarm buttons will be also deleted." : this._t('label_confirm_deletion'),
        header: this._t('label_Confirmation'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          buttons.splice(index, 1)
        }
      });
    },
    deleteAlarmPanel(name = '', showAlarmPanel = false) {
      if (name === '') {
        name = this.selectedAlarmPanel.name
      }
      deleteAlarmPanel(name).then(() => {
        if (!showAlarmPanel) {
          this.selectedAlarmPanel.name = null
        }
        this.getAlarmPanels();
        this.deleteAlarmPanelDialog = false
      })
    },
    copyTextVar() {
      this.selectedAlarmPoint.text = (this.selectedAlarmPoint.text ?? '') + this.selectedTextVar
      this.selectedTextVar = ''
    },
    syncSrcOptions() {
      switch (this.srcOption) {
        case 'EXIST':
          break
        case 'NEW':
          this.createAlarmPlan()
          break
        case 'SIMPLE':
          this.createAlarmPlan(true)
          break
      }
    },
    syncRepeatableMonitored() {
      if (this.repeatableMonitored) {
        this.selectedAlarmPoint.countRepeat = 0
        this.selectedAlarmPoint.repeatBetween = 0
      }
    },
    loadInstructions() {
      getInstructionFiles('')
          .then(res => {
            if (res) {
              this.instructions = this.getInstructionsRecursive(res.data.instructions)
              if (this.instructions.length) {
                this.instructions.sort((a, b) =>
                    a.org.fullOrgPath.localeCompare(b.org.fullOrgPath)
                    || a.name.localeCompare(b.name)
                )
              }
            }
          })
    },
    getInstructionsRecursive(instructions, collection = [], org = {}) {
      for (const instruction of instructions) {
        if (instruction.files && instruction.files.length) {
          collection.concat(this.getInstructionsRecursive(instruction.files, collection, instruction.org))
        } else {
          collection.push({...instruction, org})
        }
      }
      return collection
    },
    initNewAlarmPanelDialog() {
      this.selectedAlarmPanel = {
        "actionButton": {
          "bottom": true,
          "reset": "",
          "trigger": "",
          "unlock": ""
        },
        "allowedUsers": [],
        "backGroundColor": "",
        "description": "",
        "fontSize": 10,
        "name": "",
        "orgId": localStorage.getItem('orgId'),
        "pdfFile": {
          "file": "",
          "title": ""
        },
        "returnButton": true,
        "rows": [
          {
            "buttons": [
              {
                "custom-name": "",
                "alarmName": "",
                "alarmid": "",
                "bgColor": "",
                "existable": true,
                "id": "",
                "textColor": ""
              }
            ],
            "text": ""
          }
        ],
        "separator": true
      }
      this.newAlarmPanelDialog = true
    },
    createAlarmPanel() {
      createAlarmPanel(this.selectedAlarmPanel).then(() => {
        this.getAlarmPanel(this.selectedAlarmPanel.name)
        this.newAlarmPanelDialog = false
        this.getAlarmPanels()
      })
    },
    initCallGroupEditDialog(callGroup, simple = false) {
      this.originalCallGroup = callGroup
      this.editCallGroup = {
        participants: [],
        participantGroups: [],
        simple: simple
      }

      this.originalCallGroup.members.forEach(function (member) {
        if (member.participantId) {
          let participant = this.participants.find(o => o.value == member.participantId);
          this.editCallGroup.participants.push(participant)
        } else if (member.participantGroupId) {
          let participantGroup = this.participantGroups.find(o => o.value == member.participantGroupId);
          this.editCallGroup.participantGroups.push(participantGroup)
        }
      }, this)
      this.editCallGroupDialog = true
    },
    initCallGroupDeleteDialog(index) {
      this.deleteCallGroupIndex = index
      this.deleteCallGroupDialog = true
    },
    deleteCallGroup() {
      this.deleteCallGroupDialog = false
      this.selectedAlarmPlan.callGroups.splice(this.deleteCallGroupIndex, 1)
      this.deleteCallGroupIndex = -1
    },
    reorderCallGroupMembers(callGroup, ordered) {
      callGroup.members = ordered
      callGroup.members.forEach(function (member, index) {
        member.orderId = index
      })
    },
    updateCallGroupMembers() {
      for (let value of this.editCallGroup.participants) {
        let founded = this.originalCallGroup.members.find(o => o.participantId == value.value);
        if (!founded) {
          let orderId = this.originalCallGroup.members.length
          this.originalCallGroup.members.push({
            'participantId': value.value,
            'participantGroupId': null,
            'title': value.label,
            'name': value.label,
            'planGroupId': this.originalCallGroup.id.startsWith('_') ? null : this.originalCallGroup.id,
            'orderId': orderId
          })
        }
      }

      for (let value of this.editCallGroup.participantGroups) {
        let founded = this.originalCallGroup.members.find(o => o.participantGroupId == value.value);
        if (!founded) {
          let orderId = this.originalCallGroup.members.length
          this.originalCallGroup.members.push({
            'participantId': null,
            'participantGroupId': value.value,
            'title': value.name,
            'name': value.name,
            'planGroupId': this.originalCallGroup.id.startsWith('_') ? null : this.originalCallGroup.id,
            'orderId': orderId
          })
        }
      }
      this.editCallGroupDialog = false
      if (this.editCallGroup.simple) {
        this.syncGroupSelectRuleSimple(this.originalCallGroup)
      }

    },
    removeCallGroupMembers(callGroup, simple) {
      let selected = this.selectedCallGroupMembers[callGroup.id].filter(() => true);
      this.selectedCallGroupMembers[callGroup.id] = [];
      for (const value of selected) {
        if (value.participantId) {
          callGroup.members.splice(callGroup.members.findIndex(o => o.participantId == value.participantId), 1)
        } else if (value.participantGroupId) {
          callGroup.members.splice(callGroup.members.findIndex(o => o.participantGroupId == value.participantGroupId), 1)
        }
      }
      if (simple) {
        this.syncGroupSelectRuleSimple(callGroup)
      }
    },
    syncGroupSelectRuleSimple(callGroup) {
      if (callGroup.memberCallRuleSimple == 'OTHER') {
        this.activeTabSimple = 0
        this.syncGroupMemberCallRule(callGroup)
        return
      }
      if (this.hasParticipantGroups(callGroup)) {
        switch (callGroup.memberCallRuleSimple) {
          case 'A':
            callGroup.memberCallRule = 'ALL'
            callGroup.isRandom = false
            callGroup.isEscalation = true
            callGroup.memberSelectRule = 'ESCALATION'
            break;
          case 'B':
            callGroup.memberCallRule = 'ALL'
            callGroup.isRandom = false
            callGroup.isEscalation = true
            callGroup.memberSelectRule = 'ESCALATION'
            break;
          case 'C':
            callGroup.memberCallRule = 'ALL'
            callGroup.isRandom = true
            callGroup.isEscalation = false
            callGroup.memberSelectRule = 'RANDOM'
            break;
        }
      } else {
        switch (callGroup.memberCallRuleSimple) {
          case 'A':
            callGroup.memberCallRule = 'ALL'
            callGroup.isRandom = true
            callGroup.isEscalation = false
            callGroup.memberSelectRule = 'RANDOM'
            break;
          case 'B':
            callGroup.memberCallRule = 'ALL'
            callGroup.isRandom = true
            callGroup.isEscalation = false
            callGroup.memberSelectRule = 'RANDOM'
            break;
          case 'C':
            callGroup.memberCallRule = 'ALL'
            callGroup.isRandom = true
            callGroup.isEscalation = false
            callGroup.memberSelectRule = 'RANDOM'
            break;
        }
      }
    },
    syncGroupSelectRule(callGroup) {
      if (callGroup.memberSelectRule == 'RANDOM') {
        callGroup.isRandom = true
        callGroup.isEscalation = false
      } else if (callGroup.memberSelectRule == 'ESCALATION') {
        callGroup.isRandom = false
        callGroup.isEscalation = true
      }
      this.syncGroupMemberCallRule(callGroup)
    },
    syncGroupMemberCallRule(callGroup) {
      if (this.hasParticipantGroups(callGroup)) {
        if (callGroup.memberCallRule == 'ALL' && callGroup.isRandom == false && callGroup.isEscalation == true) {
          callGroup.memberCallRuleSimple = 'A'
        } else if (callGroup.memberCallRule == 'MINIMUM' && callGroup.isRandom == false && callGroup.isEscalation == true) {
          callGroup.memberCallRuleSimple = 'B'
        } else if (callGroup.memberCallRule == 'MINIMUM' && callGroup.isRandom == true && callGroup.isEscalation == false) {
          callGroup.memberCallRuleSimple = 'C'
        } else {
          callGroup.memberCallRuleSimple = 'OTHER'
        }
      } else {
        if (callGroup.memberCallRule == 'ALL' && callGroup.isRandom == true && callGroup.isEscalation == false) {
          callGroup.memberCallRuleSimple = 'A'
        } else if (callGroup.memberCallRule == 'MINIMUM' && callGroup.isRandom == true && callGroup.isEscalation == false) {
          callGroup.memberCallRuleSimple = 'B'
        } else if (callGroup.memberCallRule == 'PRECISELY' && callGroup.isRandom == true && callGroup.isEscalation == false) {
          callGroup.memberCallRuleSimple = 'C'
        } else {
          callGroup.memberCallRuleSimple = 'OTHER'
        }
      }
    },
    getAlarmPlan(id) {
      getAlarmPlan(id).then((response) => {
        this.selectedAlarmPlan = response.data
        this.selectedAlarmPlan.callGroups.forEach(function (callGroup) {
          if (callGroup.isRandom == true && callGroup.isEscalation == false) {
            callGroup.memberSelectRule = 'RANDOM'
          } else if (callGroup.isRandom == false && callGroup.isEscalation == true) {
            callGroup.memberSelectRule = 'ESCALATION'
          } else {
            callGroup.memberSelectRule = 'NONE'
          }
          this.syncGroupMemberCallRule(callGroup)
        }, this)
      })
    },
    getAlarmPlans() {
      getList('ALARMPLAN', 0, this.apFilterValue, 1000).then((response) => {
        this.alarmPlans = response.data.list
        this.alarmPlans.sort((a, b) => a.name.localeCompare(b.name))
        this.currentPage = response.data.currentPage
        this.totalRecords = response.data.count
        this.loading = false
      })
    },
    getParticipantGroups() {
      getList('PARTICIPANT_GROUP', 0, this.participantFilterValue, 1000).then((response) => {
        this.participantGroups = response.data.list.map(item => {
          const container = {};
          container.value = item.id;
          container.name = item.name
          return container;
        })
        this.loading = false
      })
    },
    getParticipants() {
      getList('PARTICIPANT', 0, this.participantFilterValue, 1000).then((response) => {
        this.participants = response.data.list.map(item => {
          const container = {};
          container.value = item.id;
          container.label = item.name + ' ' + item.surname
          return container;
        })
        this.loading = false
      })
    },
    saveAlarm() {
      for (let row of this.selectedAlarmPanel.rows) {
        for (let button of row.buttons) {
          if (typeof button.icon !== 'string') {
            button.icon = JSON.stringify({...button.icon, color: button.textColor})
          }
        }
      }
      updateAlarmPanel(this.selectedAlarmPanel).then(() => {
        if (this.initAlarmPanelName.length > 0 && this.selectedAlarmPanel.name !== this.initAlarmPanelName) {
          this.deleteAlarmPanel(this.initAlarmPanelName, true)
        }
        this.getAlarmPanel(this.selectedAlarmPanel.name, this.activeButtonsTab)
        this.isEditable = !this.isEditable
      })

    },
    getAlarmPoints() {
      getList('ALARMPOINT', 0, this.alarmPointSearch, 1000, this.orgId).then((response) => {
        this.alarmPoints = response.data.list.filter(function (item) {
          return item.systemId === 'PRED';
        })

        this.alarmPoints.sort((a, b) => a.name.localeCompare(b.name))

        this.alarmPointsGrp = []

        this.alarmPoints.forEach(item => {
          let group = this.alarmPointsGrp.find((g) => g.label == item.name && g.orgId == item.orgId)
          if (!group) {
            group = {
              key: 'g' + this.alarmPointsGrp.length,
              label: item.name,
              selectable: false,
              children: [],
              type: 'group',
              orgId: item.orgId
            }
            this.alarmPointsGrp.push(group)
          }
          group.children.push({
            key: item.alarmPointId,
            label: item.alarmPointId,
            selectable: true,
            type: 'alarm',
            data: item,
            orgId: item.orgId
          })
        }, this)

        this.alarmPointsGrp = this.alarmPointsGrp.map((item) => {
          if (item.children.length < 2) {
            item = item.children[0]
            item.label = item.data.name
          }
          return item
        }, this)

        this.loading = false
      })
    },
    createCallGroup() {
      this.selectedAlarmPlan.callGroups = Array.isArray(this.selectedAlarmPlan.callGroups) ? this.selectedAlarmPlan.callGroups : []

      this.newCallGroup.id = '_' + this.selectedAlarmPlan.callGroups.length
      this.newCallGroup.orgId = this.selectedAlarmPlan.orgId
      this.newCallGroup.orgName = this.selectedAlarmPlan.orgName
      this.newCallGroup.alarmPlanId = this.selectedAlarmPlan.id
      this.newCallGroup.atLeastCount = 0
      this.newCallGroup.memberCallRule = 'ALL'
      this.newCallGroup.isEscalation = false
      this.newCallGroup.isRandom = true
      this.newCallGroup.memberSelectRule = 'RANDOM'
      this.newCallGroup.memberCallRuleSimple = 'C'
      this.newCallGroup.members = []
      this.newCallGroup.version = 0
      this.newCallGroup.orderId = this.selectedAlarmPlan.callGroups ? this.selectedAlarmPlan.callGroups.length : 0

      let callGroup = Object.assign({}, this.newCallGroup)
      this.selectedAlarmPlan.callGroups.push(callGroup)
      this.newCallGroupDialog = false
      this.newCallGroup = {}
    },
    hasParticipantGroups(callGroup) {
      return callGroup.members.findIndex(o => o.participantGroupId != null) != -1
    },
    initColorDialog(rowIndex, buttonIndex, type) {
      this.selectColorDialog = true
      this.selectedRowIndex = rowIndex
      this.selectedButtonIndex = buttonIndex
      this.selectedType = type
    },
    initIconDialog(rowIndex, buttonIndex) {
      this.selectIconDialog = true
      this.selectedRowIndex = rowIndex
      this.selectedButtonIndex = buttonIndex
      if (this.selectedAlarmPanel.rows[rowIndex].buttons[buttonIndex].icon === null) {
        this.selectedAlarmPanel.rows[rowIndex].buttons[buttonIndex].icon = this.iconDialogModel
      }
    },
    prepareActivate(button) {
      this.preparedTriggerBody.button.id = button.id
      this.preparedTriggerBody.button.alarmName = button.alarmName
      this.preparedTriggerBody.button.alarmid = button.alarmid
      this.preparedTriggerBody.button['custom-name'] = button['custom-name']
      this.preparedTriggerBody.button.existable = button.existable
      this.preparedTriggerBody.button.icon = button.icon
      this.preparedTriggerBody.button.bgColor = button.bgColor
      this.triggerAgainDlg = true
      this.replacedAlarmDialogText = ''
      getAlarmPoint(button.id).then((response) => {
        this.originalAlarmDialogText = response.data.text
        this.computedAlarmDialogTextArray = this.originalAlarmDialogText.split('<original>')
        // this.preparedTriggerBody.alarmText = this.originalAlarmDialogText.replace('<original>', '<br><strong>' + this._t('label_add_custom_text_below') + '</strong><br>')
        this.preparedTriggerBody.tempAlarmPoint = response.data
        this.preparedTriggerBody.instructionFiles = response.data.instructionFiles ? response.data.instructionFiles : []

      });
    },
    activate() {
      activateAlarmPanel(this.preparedTriggerBody).then(() => {
        this.triggerAgainDlg = false
      });
      this.preparedTriggerBody.tempAlarmPoint.instructionFiles = this.preparedTriggerBody.instructionFiles
      updateAlarmPoint(this.preparedTriggerBody.tempAlarmPoint)
          .then(() => {
            this.$root.showMessage('Alarm triggered', 'success')
            // this.$router.push(`/alarm-table`)
          })
          .catch((error) => {
            console.log(error)
            let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
            this.$root.showMessage((data.reason ? ': ' + data.reason : ''), 'error')
          })
    },
    selectVariable() {
      this.preparedTriggerBody.alarmText += this.selectedVar
      this.selectedVar = null
    },
    cancelTriggerAlarm() {
      this.preparedTriggerBody.alarmText = ''
      this.preparedTriggerBody.priority = 1
      this.preparedTriggerBody.button = {}
      this.triggerAgainDlg = false
    },
    createNewTab() {
      this.selectedAlarmPanel.rows.push({text: 'Tab ' + this.selectedAlarmPanel.rows.length, buttons: []})
    },
    downloadInstruction(instructionFile) {
      downloadInstruction(instructionFile)
    },
    addInstruction() {
      if (!this.preparedTriggerBody.instructionFiles) {
        this.preparedTriggerBody.instructionFiles = [];
      }
      if (this.preparedTriggerBody.instructionFiles.indexOf(this.preparedTriggerBody.instructionFile) === -1) {
        this.preparedTriggerBody.instructionFiles.push(this.preparedTriggerBody.instructionFile);
      }
      this.preparedTriggerBody.instructionFile = null;
    },
    deleteIntruction(instruction) {
      this.preparedTriggerBody.instructionFiles
          .splice(this.preparedTriggerBody.instructionFiles.findIndex(i => i === instruction), 1)
    },
  }
}
</script>
<style scoped>
.color_sample {
  display: inline-block;
  width: 48px;
  height: 48px;
  border-radius: 4px;
  margin: 0 auto;
}

.icon_big {
  font-size: 48px;
  padding: 4px;
  border-radius: 4px;
}

.icon_medium {
  font-size: 24px;
  padding: 4px;
  border-radius: 4px;
}

.icon {
  font-size: 24px;
}

.p-listbox.grp .p-listbox-list .p-listbox-item {
  padding-left: 2em;
}

.overflow-card-text {
  max-width: 15rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

::v-deep(#triggerAgainDlg.p-dialog .p-dialog-header) {
  padding: 0 !important;
}

::v-deep(.withCustomTabs.hiddenTabs > .p-tabview-nav-container) {
  display: none;
}
</style>
<style>
.p-confirm-dialog-message {
  white-space: pre-line;
}
</style>
